import { apiFilters } from '@/config/axios';
import {
	GET_VIDEO_PROGRESS, SAVE_VIDEO_PROGRESS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Videos {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getVideoProgress(userId, name) {
		const { method, endpoint } = GET_VIDEO_PROGRESS;
		return this.data[method](endpoint(userId, name)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	saveVideoProgress(userId, payload) {
		const { method, endpoint } = SAVE_VIDEO_PROGRESS;
		return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Videos;
