<template>
	<div>
		<b-button
			pill
			class="text-white"
			data-count="0"
			:class="selectedButtonClass"
			@click="toggleList">
			<span class="fa fa-list-alt" />
		</b-button>
		<transition
			:css="false"
			@enter="slideEnter"
			@leave="slideLeave">
			<b-card
				v-show="opened"
				id="stickyDiv"
				header-class="step-list-header"
				body-class="step-list-body">
				<template #header>
					<div class="d-flex justify-content-between align-items-center">
						<div>
							<span class="fa fa-list-alt ml-1 mr-2" />
							{{ translate('account_steps') }}
						</div>
						<div>
							{{ steps.length > 0 ? `${completeSteps}/${steps.length}` : '' }}
						</div>
					</div>
				</template>
				<div v-if="!loading">
					<account-step-item
						v-for="(stepData, index) in steps"
						:key="stepData.id"
						:step-name="stepData.attributes.step"
						:step-info="stepData.attributes"
						:show-bottom-line="index < steps.length - 1"
						@closeSteps="toggleList" />
				</div>
				<is-loading
					class="text-white"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="steps.length > 0" />
			</b-card>
		</transition>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import Dashboard from '@/util/Dashboard';
import {
	Auth,
	Profile,
	Network,
	AccountSteps,
} from '@/translations';
import AccountStepItem from '@/views/Layouts/Main/AccountStepItem.vue';
import EventBus from '@/util/eventBus';

export default {
	name: 'AccountStepsList',
	messages: [Auth, Network, Profile, AccountSteps],
	components: {
		AccountStepItem,
		// AppHeaderDropdown,
	},
	mixins: [WindowSizes],
	props: {
		showDashboard: {
			type: Boolean,
			default: false,
		},
		showOnlyLogin: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			stepsList: new Dashboard(),
			opened: false,
			selectedButtonClass: {
				'btn-info': true,
				'closed-card': true,
			},
		};
	},
	computed: {
		loading() {
			return !!this.stepsList.data.loading;
		},
		steps() {
			let userSteps = [];
			try {
				userSteps = this.stepsList.data.response.data.data;
				if (typeof userSteps === 'undefined') {
					userSteps = [];
				}
			} catch (error) {
				userSteps = [];
			}
			return this.sortSteps(userSteps);
		},
		completeSteps() {
			return this.steps.filter((stepsItem) => stepsItem.attributes.status === 'complete').length;
		},
		missingSteps() {
			return !this.$user.details().steps_completed || false;
		},
	},
	watch: {
		opened() {
			this.selectedButtonClass = {
				'btn-info': !this.opened,
				'bg-success': this.opened,
				'closed-card': !this.opened,
			};
		},
	},
	mounted() {
		if (this.missingSteps && !['sm', 'xs'].includes(this.windowWidth)) {
			this.toggleList();
		}

		EventBus.$on('updateAccountSteps', () => {
			this.getSteps();
		});
	},
	methods: {
		slideEnter(el) {
			el.style.transform = 'translateX(100%)';
			el.style.transition = 'transform 0.5s';
			setTimeout(() => {
				el.style.transform = 'translateX(0)';
			});
		},
		slideLeave(el, done) {
			el.style.transform = 'translateX(0)';
			el.style.transition = 'transform 0.5s';
			setTimeout(() => {
				el.style.transform = 'translateX(100%)';
				setTimeout(done, 500);
			});
		},
		toggleList() {
			this.opened = !this.opened;
			if (this.opened) {
				this.getSteps();
			}
		},
		getSteps() {
			this.stepsList.clear();
			this.stepsList.getAccountSteps();
		},
		sortSteps(stepList) {
			const sortList = [
				'missing_requirements',
				'incomplete',
				'cannot_complete',
				'complete',
			];
			stepList.sort((a, b) => {
				const aIndex = sortList.indexOf(a.attributes.status);
				const bIndex = sortList.indexOf(b.attributes.status);

				if (aIndex === -1) return 1;
				if (bIndex === -1) return -1;

				return aIndex - bIndex;
			});
			return stepList;
		},
	},
};
</script>

<style scoped>
.card{
	max-width: 500px; /* Max Width of the card (depending on the container!) */
}

.open-card {
	background: #11324d !important;
	color: white;
}

.step-list-header {
	background: #e0703d !important;
	color: white;
	border: none;
}

.step-list-body {
	background-color: #002D74 !important;
	border: none;
	box-shadow: none;
}

#stickyDiv {
	position: fixed;
	top: 55px;
	right: 0px;
	display: block;
}
</style>
