<template>
	<div
		v-show="show"
		v-b-tooltip.hover.html
		:title="tooltipContent"
		class="text-muted">
		<i class="far fa-clock" /> <span v-if="show && !compact">{{ timeNow }} {{ tz }}</span>
	</div>
</template>
<script>
import { TIMEZONE, TIMEZONE_NAME } from '@/settings/General';

export default {
	name: 'Clock',
	props: {
		compact: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateNow: '',
			timeNow: '',
			interval: null,
			tz: TIMEZONE,
		};
	},
	computed: {
		tooltipContent() {
			return `<span>${ this.dateNow }</span><br><span>${ this.timeNow } (${ this.tz })</span>`;
		},
		show() {
			return this.timeNow !== '';
		},
	},
	mounted() {
		this.interval = setInterval(this.time, 1000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		time() {
			const dateTime = this.$moment().tz(TIMEZONE_NAME);
			this.dateNow = dateTime.format('LL');
			this.timeNow = dateTime.format('hh:mm A');
		},
	},
};
</script>
