export const VALID_REDIRECTIONS = {
	qualification: {
		name: 'Home',
		height: {
			xs: 1831,
			sm: 868,
			md: 700,
			lg: 500,
		},
		emit: null,
	},
	rankProgress: {
		name: 'Home',
		height: {
			xs: 2300,
			sm: 1350,
			md: 700,
			lg: 500,
		},
		emit: null,
	},
	risingStar: {
		name: 'Home',
		height: {
			xs: 2630,
			sm: 1350,
			md: 700,
			lg: 500,
		},
		emit: {
			name: 'changeWidget',
			emitData: {
				index: 'rising_star',
			},
		},
	},
};

export default {};
