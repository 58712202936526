<template>
	<b-modal
		id="video-modal"
		v-model="shouldShowModal"
		centered
		no-close-on-escl
		no-close-on-backdrop
		size="xl"
		:hide-header="shouldHideHeader"
		:hide-footer="hideFooter">
		<vue-vimeo-player
			ref="player"
			:options="playerOptions"
			:video-id="data.vimeo_id"
			autoplay
			@ready="onReady()"
			@timeupdate="onTimeUpdate($event)"
			@seeking="onSeeking($event)"
			@seeked="onSeeked()"
			@ended="onEnded()"
			@pause="onStop($event)" />
	</b-modal>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
	name: 'VideoModal',
	components: {
		vueVimeoPlayer,
	},
	props: {
		modalShow: {
			type: Boolean,
		},
		hideHeader: {
			type: Boolean,
			default: true,
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		data: {
			type: Object,
			default() {
				return {
					time_displayed: 0,
				};
			},
		},
	},
	data() {
		return {
			oldCurrentTime: 0,
			currentTime: 0,
			isSeeking: false,
			playerOptions: {
				responsive: true,
				speed: false,
			},
			showHeader: false,
			lastSavedTime: 0,
		};
	},
	computed: {
		shouldShowModal: {
			get() {
				return this.modalShow;
			},
			set(newValue) {
				this.$emit('close', newValue);
			},
		},
		shouldHideHeader() {
			return this.hideHeader && !this.showHeader && !this.data.seen;
		},
	},
	watch: {
		currentTime(newValue, oldValue) {
			this.oldCurrentTime = oldValue;
			if (newValue > this.lastSavedTime + 5) {
				this.saveTime(newValue);
			}
		},
	},
	methods: {
		onReady() {
			if (this.modalShow && !this.data.seen) {
				this.$refs.player.player.setCurrentTime(Math.floor(this.data.time_displayed));
			}
		},
		onStop(player) {
			this.saveTime(player.seconds);
		},
		onTimeUpdate(player) {
			if (!this.isSeeking) {
				this.currentTime = player.seconds;
			}
		},
		onSeeking(player) {
			this.isSeeking = true;
			if (player.seconds > this.oldCurrentTime && !this.data.seen) {
				this.$refs.player.player.setCurrentTime(Math.floor(this.oldCurrentTime));
			}
		},
		onSeeked() {
			this.isSeeking = false;
		},
		onEnded() {
			this.showHeader = true;
			this.saveTime(this.currentTime, { show: 0 });
		},
		saveTime(newValue, options = {}) {
			if (!this.data.seen) {
				this.lastSavedTime = newValue;
				const payload = {
					time_displayed: this.currentTime,
					...options,
				};
				this.$emit('saveProgress', payload);
			}
		},
	},
};
</script>
<style scoped>
