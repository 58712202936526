<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<a
			class="notification text-muted pointer"
			@click="handleEnvelopeClick">
			<i class="far fa-envelope" />
			<span
				v-if="notificationsTotal > 0"
				class="badge">{{ notificationsTotal > 9 ? '9+' : notificationsTotal }}</span>
			<!-- <span
				v-else-if="notificationsInfo.unseen_mobile_notifications > 0"
				class="badge mobile-badge"
				v-html="'&nbsp;'" /> -->
		</a>
	</div>
</template>
<script>
import { REFRESH_NOTIFICATIONS } from '@/settings/General';
import Notifications from '@/util/Notifications';

export default {
	name: 'NotificationsAlert',
	props: {
		//
	},
	data() {
		return {
			notifications: new Notifications(),
			markNotifications: new Notifications(),
			interval: null,
		};
	},
	computed: {
		notificationsInfo() {
			try {
				const { response } = this.notifications.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		notificationsTotal() {
			try {
				return this.notificationsInfo.unseen_notifications + this.notificationsInfo.unseen_mobile_notifications;
			} catch (error) {
				return 0;
			}
		},
	},
	beforeMount() {
		this.notifications.getNotificationsInfo();
	},
	mounted() {
		this.interval = setInterval(() => {
			this.notifications.getNotificationsInfo();
		}, REFRESH_NOTIFICATIONS);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		handleEnvelopeClick() {
			this.$router.push({ name: 'MobileNotifications' }).then(() => {
				this.markNotifications.markNotificationsAsSeen().then(() => {
					this.notifications.getNotificationsInfo();
				});
			}).catch(() => {});
		},
	},
};
</script>

<style>
	.notification {
		text-decoration: none;
		padding: 2px 7px;
		position: relative;
		display: inline-block;
		border-radius: 2px;
	}

	.notification .badge {
		position: absolute;
		top: -2px;
		right: -5px;
		padding: 2px 5px;
		border-radius: 100%;
		background-color: red;
		color: white;
	}

	.notification .mobile-badge {
		position: absolute;
		top: -1px;
		right: -0px;
		padding: 0px 4px;
		border-radius: 100%;
		background-color: red;
		color: white;
	}
</style>
