export const VIDEO_NAMES = {
	withdrawal: {
		name: 'withdrawal',
		validCountries: ['US', 'CA'],
	},
	comp_plan_rewards: {
		name: 'comp_plan_rewards',
	},
};

export default {};
