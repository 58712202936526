<template>
	<b-row
		v-b-tooltip.hover.html.left="`<div class='custom-tooltip'> ${ translate(`${stepName}_description`) } </div>`"
		class="mb-2"
		:class="showBottomLine ? 'pb-2' : ''"
		:style="{
			borderBottom: showBottomLine ? '1px solid #606773' : 'none',
			marginLeft: '5px',
			marginRight: '5px',
		}">
		<b-col class="col-auto px-1">
			<div
				class="circle-check text-white d-inline-block"
				:class="`bg-${stepColor}`">
				<i :class="`fa ${stepIcon}`" />
			</div>
		</b-col>
		<b-col>
			<div class="d-flex justify-content-between align-items-center text-left account-step pt-1">
				<span
					class="ml-1 custom-tooltip"
					:class="stepInfo.reference_type !== null ? 'pointer a' : ''"
					@click="handleOption">
					<div class="custom-tooltip">
						<p
							class="text-bold mb-1 text-md"
							:class="stepInfo.status === 'complete' ? 'success-alt' : 'text-white'">
							{{ translate(stepName) }}
						</p>
					</div>
				</span>
			</div>
		</b-col>
		<video-modal
			:modal-show="modalShow"
			:data="videoData"
			:hide-header="false"
			@saveProgress="saveVideoProgress"
			@close="closeModal" />
	</b-row>
</template>
<script>
import {
	AccountSteps, Auth, Network, Profile,
} from '@/translations';
import { VIDEO_NAMES } from '@/settings/Videos';
import Videos from '@/util/Videos';
import VideoModal from '@/views/Videos/VideoModal';
import EventBus from '@/util/eventBus';

export default {
	name: 'AccountStepItem',
	messages: [Auth, Network, Profile, AccountSteps],
	components: { VideoModal },
	props: {
		stepName: {
			type: String,
			default: '',
		},
		stepInfo: {
			type: Object,
			default: () => {},
		},
		showBottomLine: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			interval: null,
			modalShow: false,
			videoName: VIDEO_NAMES.comp_plan_rewards.name,
			videoData: {},
			video: new Videos(),
		};
	},
	computed: {
		stepColor() {
			switch (this.stepInfo.status) {
			case 'incomplete':
				return 'incomplete';
			case 'missing_requirements':
				return 'warning';
			case 'cannot_complete':
				return 'danger';
			case 'complete':
				return 'success-alt';
			default:
				return 'secondary';
			}
		},
		stepIcon() {
			switch (this.stepInfo.status) {
			case 'incomplete':
				return 'fa-check';
			case 'missing_requirements':
				return 'fa-exclamation';
			case 'cannot_complete':
				return 'fa-times';
			case 'complete':
				return 'fa-check';
			default:
				return 'fa-times';
			}
		},
	},
	methods: {
		handleOption() {
			switch (this.stepInfo.reference_type) {
			case 'redirect':
				this.$router.push({ name: this.stepInfo.reference });
				break;
			case 'video':
				this.videoName = this.stepInfo.reference;
				this.showVideoModal();
				this.$emit('closeSteps');
				break;
			case 'scroll':
				EventBus.$emit('scrollToReference', this.stepInfo.reference);
				break;
			default:
				break;
			}
		},
		showVideoModal() {
			this.video.getVideoProgress(this.$user.details().id, this.videoName).then((response) => {
				this.modalShow = true;
				this.videoData = { ...response.response.data, seen: !response.response.show };
			});
		},
		saveVideoProgress(payload) {
			payload.name = this.videoName;
			this.video.saveVideoProgress(this.$user.details().id, payload);
		},
		closeModal() {
			this.modalShow = false;
			this.$emit('closeSteps');
		},
	},
};
</script>

<style scoped>
.bg-incomplete {
	background-color: #797671;
}

.circle-check {
	vertical-align: middle;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: auto;
	padding: 6px;
}
</style>

<style>
.tooltip-inner {
	max-width: 250px;
}
</style>
